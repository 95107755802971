import React from 'react';

function Footer(props) {
    return (
        <footer class="footer">
            <div class="content has-text-centered">
                <p>
                <strong>opensight.ch</strong> subscription manager by <a href="https://opensight.ch">roman huesler</a>. 
                </p>
            </div>
        </footer>
    )
}

export default Footer;