import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import {getBuchungen, updateTransaction} from 'store/buchungenSlice';
import useForm from 'components/useForm';
import moment from 'moment';
import Input from 'components/input';
import Pickaccount from 'modules/common/pickaccount/pickaccount';
import { useNavigate } from 'react-router-dom';
import {initializeTransactionBrowser} from 'store/transactionBrowserSlice';

function TransactionDetails(props) {
    const [transaction, setTransaction] = useState({});
    const routeParams = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { form, handleChange, setForm, setInForm, resetForm, handleSubmit } = useForm({
        datum: moment(Date.now()).format("yyyy-MM-DD"),
        bemerkungen: '',
        betrag: '0',
        kontoSoll: '16',
        konto_haben_id: '54'
    }, submitted);

    async function submitted() {
        await dispatch(updateTransaction({
            id: routeParams.id,
            ...form
        }))
        await dispatch(getBuchungen())
        dispatch((initializeTransactionBrowser(props.transactions)))
        navigate("/transactions")
    }

    useEffect(() => {
        dispatch(getBuchungen())
    }, []);

    useEffect(() => {
        setInForm("datum", moment(transaction.Datum).format("yyyy-MM-DD"))
        setInForm("bemerkungen", transaction.Bemerkungen)
        setInForm("betrag", transaction.Betrag)
        setInForm("konto_soll_id", transaction.Konto_Soll_Id)
        setInForm("konto_haben_id", transaction.Konto_Haben_Id)
        console.log(transaction)
    }, [transaction]);

    useEffect(() => {
        const transactionId = routeParams.id;
        setTransaction(_.find(props.transactions, t => { return t.Id == transactionId }))
    }, [props.transactions]);

    return (
        <React.Fragment>
            <h1>Transaction Details</h1>
            {transaction && 
                <React.Fragment>
                <form onSubmit={handleSubmit}>
                <div>
                    {/* DATUM */}
                    <Input
                        label="Datum"
                        type="date"
                        id="datum"
                        name="datum"
                        value={form.datum}
                        onChange={handleChange}
                        placeholder=" Choose a date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
                <div>
                    {/* BEMERKUNGEN */}
                    <Input
                            label="Bemerkungen"
                            type="text"
                            name="bemerkungen"
                            value={form.bemerkungen}
                            onChange={handleChange}
                        />
                </div>
                <div>
                    {/* BETRAG */}
                    <Input
                            label="Betrag"
                            type="text"
                            name="betrag"
                            value={form.betrag}
                            onChange={handleChange}
                        />
                </div>

                {/* KONTO HABEN */}
                <div>
                    <Pickaccount 
                        id="konto_haben_id"
                        name="konto_haben_id"
                        label="Konto - Haben"
                        value={form.konto_haben_id}
                        onChange={handleChange}
                    />
                </div>

                {/* KONTO SOLL */}
                <div>
                    <Pickaccount 
                        id="konto_soll_id"
                        name="konto_soll_id"
                        label="Konto - Soll"
                        value={form.konto_soll_id}
                        onChange={handleChange}
                    />
                </div>
                {/* SAVE */}
                <input
                    className='button is-primary'
                    type="submit"
                    value="Speichern" />
                </form>
                </React.Fragment>
            }
            {
                // JSON.stringify(form)
            }
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { transactions: state.buchungenSlice.buchungen }
}
  
export default connect(mapStateToProps)(TransactionDetails)
