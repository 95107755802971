import Joi from 'joi-browser';

// product object schema validation
const schema = {
    subscriptionManagerAPIURL: Joi.string().required().label('Subscription Manager API URL'),
    subscriptionManagerAPIToken: Joi.string().required().label('Subscription Manager API Token'),
    jenkinsURL: Joi.string().required().label('Jenkins URL'),
    jenkinsUsername: Joi.string().required().label('Jenkins Username'),
    jenkinsToken: Joi.string().required().label('Jenkins Token')
}


export default function validateSettings(settings) {
    const options = { abortEarly: false }
    const { error } = Joi.validate(settings, schema, options);

    // return empty object if there are no errors
    if(!error) return {};

    // prepare output object
    const errors = {};

    // prepare errors object
    for(let item of error.details)
        errors[item.path[0]] = item.message;

    return errors;
}
