import { combineReducers } from '@reduxjs/toolkit';
import loginSlice from './loginSlice';
import logsSlice from './logsSlice';
import infoSlice from './infoSlice';
import apiTokenSlice from './apiTokenSlice';
import settingsSlice from './settingsSlice';
import usersSlice from './usersSlice';
import transactionBrowserSlice from './transactionBrowserSlice';
import kontoSlice from './kontoSlice';
import totalAssetsSlice from './totalAssetsSlice';
import gewinnUndVerlustSlice from './gewinnUndVerlustSlice';
import buchungenSlice from './buchungenSlice';
import balanceSlice from './balanceSlice';
import budgetPositionSlice from './budgetPositionSlice';

const createReducer = asyncReducers =>
	combineReducers({
		infoSlice,
		apiTokenSlice,
		settingsSlice,
		usersSlice,
		loginSlice,
		logsSlice,
		transactionBrowserSlice,
		totalAssetsSlice,
		gewinnUndVerlustSlice,
		budgetPositionSlice,
		kontoSlice,
		balanceSlice,
		buchungenSlice,
		...asyncReducers
	});

export default createReducer;
