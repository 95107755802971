import React from 'react';
import './menu.css'


function Menu(props) {
    return (
        <nav className="navbar-primary">

        </nav>
    )
}

export default Menu;