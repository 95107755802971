import React, {useEffect, useState} from 'react';
import {IsAdmin} from 'modules/login/hasrole';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { getApiTokens, createApiToken, deleteApiToken } from 'store/apiTokenSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'components/card';

function ApiTokens(props) {
    const dispatch = useDispatch();

    const columns = [
        {
            name: 'token',
            selector: row => row.token,
            width: 'auto'
        },
        {
            name: 'created',
            selector: row => row.created
        },
        {
            name: 'creator',
            selector: row => row.creator.email || ''
        },
        {
            name: '',
            selector: row => <FontAwesomeIcon icon="fa-solid fa-trash" className='m-2' role='button' onClick={()=>deleteToken(row._id)} />
        }
    ];

    useEffect(() => {
        dispatch(getApiTokens());
	}, []);

    async function createToken() {
        dispatch(createApiToken());
    }

    async function deleteToken(tokenId) {
        dispatch(deleteApiToken(tokenId));
    }

    return (
        <IsAdmin>
            <h2>Administration</h2>
            
            <Card title="API Tokens">
                <button className='button is-primary' onClick={createToken}>Hinzufügen</button>

                { props.tokens &&
                    <DataTable
                        columns={columns}
                        data={props.tokens}
                        pagination
                        persistTableHead
                    />
                }
            </Card>
        </IsAdmin>
    )
}

function mapStateToProps(state) {
    return { 
        tokens: state.apiTokenSlice.apiTokens 
    }
}
  
export default connect(mapStateToProps)(ApiTokens)