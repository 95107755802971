import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Transactions from 'modules/transactions/transactions';
import KontoChart from 'modules/chart/chart';
import * as _ from "lodash";
import Input from 'components/input';
import { connect, useDispatch } from 'react-redux';
import Title from 'modules/common/title/title';
import AccountMetadata from './accountMetadata';
import {getBuchungen} from 'store/buchungenSlice';
import AccountBudget from './accountBudget';

function KontoDetails(props) {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const [konto, setKonto] = useState();
    const [activeTab, setActiveTab] = useState("chart");
    const [accountTransactions, setAccountTransactions] = useState([]);

    // fire initial value
    useEffect(() => {
        const { konten } = props;
        const kontoId = routeParams.id;
        setKonto(_.find(konten, k => { return k.Id == kontoId }));

        // get all transactions
        dispatch(getBuchungen());
	}, [routeParams.id]);

    // load transactions
    useEffect(() => {
        const { transactions } = props;
        if(!konto) return;
        const accountTransactions = _.filter(transactions, r => {
            return r.Konto_Haben_Id == konto.Id || r.Konto_Soll_Id == konto.Id
        });
		setAccountTransactions(accountTransactions);
	}, [konto, props.transactions]);

    return (
        <div>
            { konto && 
                <React.Fragment>
                    <Title title={konto.Name} />
                    <div class="tabs is-boxed">
                        <ul>
                            <li className={activeTab == "chart" && "is-active"}>
                                <a onClick={()=>setActiveTab("chart")}>
                                    <span class="icon is-small"><i className="fa fa-line-chart" aria-hidden="true"></i></span>
                                    <span>Chart</span>
                                </a>
                            </li>
                            <li className={activeTab == "metadata" && "is-active"}>
                                <a onClick={()=>setActiveTab("metadata")}>
                                    <span class="icon is-small"><i class="fa fa-info" aria-hidden="true"></i></span>
                                    <span>Metadaten</span>
                                </a>
                            </li>
                            <li className={activeTab == "transactions" && "is-active"}>
                                <a onClick={()=>setActiveTab("transactions")}>
                                    <span class="icon is-small"><i class="fa fa-exchange" aria-hidden="true"></i></span>
                                    <span>Transaktionen</span>
                                </a>
                            </li>
                            <li className={activeTab == "budget" && "is-active"}>
                                <a onClick={()=>setActiveTab("budget")}>
                                    <span class="icon is-small"><i class="fa fa-cube" aria-hidden="true"></i></span>
                                    <span>Budget</span>
                                </a>
                            </li>
                        </ul>
                        </div>
                    
                    <div className="pl-5 pr-5">
                    { activeTab == "chart" &&
                        <KontoChart kontoId={konto.Id} />
                    }

                    { activeTab == "metadata" &&
                        <AccountMetadata />
                    }

                    { activeTab == "transactions" &&
                        <Transactions transactions={accountTransactions} />
                    }

                    { activeTab == "budget" &&
                        <AccountBudget />
                    }
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        transactions: state.buchungenSlice.buchungen,
        konten: state.kontoSlice.konten
    };
  }
  
export default connect(mapStateToProps)(KontoDetails)