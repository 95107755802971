import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { 
	getAllTransactions as serviceGetBuchungen, 
	createTransaction as serviceCreateTransaction,
	updateTransaction as serviceUpdateTransaction } from 'services/transactions';
import _ from '@lodash';

export const getBuchungen = () => async dispatch => {
	let model = await serviceGetBuchungen();
	
	model = _.orderBy(model, function(dateObj) {
		return new Date(dateObj.Datum);
	}).reverse();

	dispatch(setBuchungen(model))
}

export const createBuchung= createAsyncThunk(
	'financeApp/transactionBrowser/createTransaction',
	async (transaction, { getState, dispatch }) => {
		let newTransaction = await serviceCreateTransaction(transaction.datum, transaction.betrag, transaction.bemerkungen, transaction.kontoHaben, transaction.kontoSoll);
		dispatch(addTransaction(newTransaction));
	}
);

export const updateTransaction= createAsyncThunk(
	'financeApp/transactionBrowser/updateTransaction',
	async (transaction, { getState, dispatch }) => {
		let updatedTransaction = await serviceUpdateTransaction(transaction.id, transaction.datum, transaction.betrag, transaction.bemerkungen, transaction.konto_haben_id, transaction.konto_soll_id);
		dispatch(getBuchungen());
	}
);

const buchungenSlice = createSlice({
	name: 'financeApp/buchungen',
	initialState: {buchungen: []},
	reducers: {
        setBuchungen: (state, action) => { return { buchungen: action.payload}},
		addTransaction: (state, action) => { return { buchungen: [...state.buchungen, action.payload] } }
	}
});

export const { setBuchungen, addTransaction } = buchungenSlice.actions;
export default buchungenSlice.reducer;
