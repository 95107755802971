import React from 'react';
import {useDispatch} from 'react-redux';
import { useEffect } from 'react';
import { logoutUser } from 'store/loginSlice';
import { useNavigate } from 'react-router-dom';

function Logout(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(async () => {
        await dispatch(logoutUser())
        navigate("/products")
	}, [dispatch]);

    return (
        <h1>Logout</h1>
    )
}

export default Logout;