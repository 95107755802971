import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {getAllSettings, saveSetting} from 'store/settingsSlice';
import UserInfo from './userinfo';
import {useDispatch} from 'react-redux';
import Input from 'components/input';
import validateSettings from './common/validation';
import useForm from 'components/useForm';
import * as _ from 'lodash';

function Settings(props) {
    const dispatch = useDispatch();
    const settings = [
        "subscriptionManagerAPIURL", 
        "subscriptionManagerAPIToken", 
        "jenkinsURL",
        "jenkinsUsername",
        "jenkinsToken"]

    let dictionary = Object.assign({}, ...settings.map((x) => ({[x]: ''})));

    const { form, handleChange, handleSubmit, formErrors, formValid, setInForm } = useForm(
        Object.assign({}, ...settings.map((x) => ({[x]: ''})))
        , submitted, validateSettings);

    useEffect(() => {
        dispatch(getAllSettings());
	}, []);

    useEffect(() => {
        for(let setting of settings) {
            const currentValue = _.find(props.settings, s => {return s.name === setting})
            setInForm(setting, currentValue ? currentValue.value : '');
        }
	}, props.settings);

    function submitted() {
        for(let setting of settings) {
            dispatch(saveSetting({name: setting, value: form[setting], metadata: {}}));
        }
	}
    
    return (
        <React.Fragment>
            <h1>Settings</h1>

            <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">

                {
                    _.map(settings, setting => (
                        <Input 
                            id={setting}
                            name={setting}
                            label={setting}
                            error={formErrors[setting]}
                            value={form[setting]}
                            onChange={handleChange}
                        />
                    ))
                }

                {/* SAVE */}
                <input
                    className='button is-primary'
                    type="submit"
                    value="Speichern" />

            </form>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { 
        settings: state.settingsSlice.settings,
    };
  }
  
export default connect(mapStateToProps)(Settings)