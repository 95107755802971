import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as _ from "lodash";
import { getApiVersion as serviceGetApiVersion } from 'services/info';

const initialState = {
	apiVersion: ''
};

export const getApiVersion = () => async dispatch => {
	const version = await serviceGetApiVersion();

    return dispatch(setVersion(version.version));
};

const infoSlice = createSlice({
	name: 'info/manage',
	initialState,
	reducers: {
		setVersion: (state, action) => {
			return {...state, apiVersion: action.payload};
		}
	},
	extraReducers: {}
});

export const { setVersion } = infoSlice.actions;

export default infoSlice.reducer;