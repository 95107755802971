import axios from 'axios';

// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && 401 === error.response.status) {
        console.log("interceptor: token expired")
        localStorage.removeItem("token");
        window.location = "/login"; 
    } else {
        return Promise.reject(error);
    }
});

function fetchAuthHeader() {
   const accessToken = localStorage.getItem("token");

    return {
        'x-auth-token': `Bearer ${accessToken}`
    }
}

export function get(url) {
    return new Promise(async (resolve, reject) => {
        try{
            let {data} = await axios.get(url, {
                headers: fetchAuthHeader()
            });
            resolve(data);
        }catch(ex) {
            reject(ex)
        }
    });
}

export function del(url, data=null) {
    return new Promise(async (resolve, reject) => {
        try{
            let {result} = await axios.delete(url, {
                headers: fetchAuthHeader(),
                data: data ? data : null
            });
            resolve(result);
        }catch(ex) {
            reject(ex)
        }
    });
}

export function post(url, postData) {
    return new Promise(async (resolve, reject) => {
        try{
            let {data} = await axios.post(url, postData, {
                headers: fetchAuthHeader()
            });
            resolve(data);
        }catch(ex) {
            reject(ex)
        }
    });
}

export function put(url, postData) {
    return new Promise(async (resolve, reject) => {
        try{
            let {data} = await axios.put(url, postData, {
                headers: fetchAuthHeader()
            });
            resolve(data);
        }catch(ex) {
            reject(ex)
        }
    });
}

export function postFile(url, postData) {
    return new Promise(async (resolve, reject) => {
        try{
            let header = fetchAuthHeader();
            header =  {
                ...header,
                'Content-Type': 'multipart/form-data'
            }
            let {data} = await axios.put(url, postData, {
                headers: header
            });
            resolve(data);
        }catch(ex) {
            reject(ex)
        }
    });
}
