import _ from '@lodash';
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { getKontos, selectKonten } from 'store/kontoSlice';
import Konto from 'modules/konto/konto';
import { connect, useDispatch } from 'react-redux'
import useForm from 'components/useForm';
import Panel from 'modules/common/panel/panel';
import Input from 'components/input';
import moment from 'moment';
import { getGewinnUndVerlust } from 'store/gewinnUndVerlustSlice';

import { 
	date_startOfYear,
	date_endOfYear,
	zeitraumDiesesJahr,
	zeitraumAngefangenesJahr,
	zeitraumLetztesJahr,
	zeitraumDieserMonat,
	zeitraumLetzterMonat,
	date_today
} from 'services/zeitpunkte'

function Income(props) {
    const dispatch = useDispatch();
    const [einnahmenKonten, setEinnahmenKonten] = useState([]);
    const [ausgabenKonten, setAusgabenKonten] = useState([]);

    useEffect(() => {
        dispatch(getKontos());
	}, []);

    useEffect(() => {
        const { accounts } = props;
        setEinnahmenKonten(_.filter(accounts, konto => { return konto.kontotyp == "Einnahmen"}));
        setAusgabenKonten(_.filter(accounts, konto => { return konto.kontotyp == "Ausgaben"}));
	}, [props.accounts]);

	const { form, handleChange, setForm, setInForm, resetForm } = useForm({
        datumVon: moment(date_startOfYear).format("yyyy-MM-DD"),
        datumBis: moment(date_today).format("yyyy-MM-DD")
    });

    useEffect(() => {
        dispatch(getGewinnUndVerlust({dateFrom: form.datumVon, dateTo: form.datumBis}))
	},[form]);

	return (
		<div>
			<Panel title="Zeitraum">
				<div>
                    {/* DATUM VON */}
                    <Input
                        label="Datum Von"
                        type="date"
                        id="datumVon"
                        name="datumVon"
                        value={form.datumVon}
                        onChange={handleChange}
                        placeholder=" Choose a date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
                <div className='ml-2'>
                    {/* DATUM BIS */}
                    <Input
                        label="Datum Bis"
                        type="date"
                        id="datumBis"
                        name="datumBis"
                        value={form.datumBis}
                        onChange={handleChange}
                        placeholder=" Choose a date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
				<button className='button is-primary ml-4 mt-4' onClick={()=>setForm(zeitraumAngefangenesJahr)}>Angefangenes Jahr</button>
				<button className='button is-primary ml-4 mt-4' onClick={()=>setForm(zeitraumDiesesJahr)}>Dieses Jahr</button>
				<button className='button is-primary ml-4 mt-4' onClick={()=>setForm(zeitraumLetztesJahr)}>Letztes Jahr</button>
				<button className='button is-primary ml-4 mt-4' onClick={()=>setForm(zeitraumDieserMonat)}>Dieser Monat</button>
				<button className='button is-primary ml-4 mt-4' onClick={()=>setForm(zeitraumLetzterMonat)}>Letzter Monat</button>
			</Panel>
			<section>
				<h2>Ausgaben</h2>
				<hr />
				{_.map(ausgabenKonten, konto => (
						<Konto 
							id={konto.Id} 
							konto={konto} 
							zeitpunktVon={form.datumVon}
							zeitpunktBis={form.datumBis} />
				))}
                {/* total ausgaben */}
                <div className="media-content" style={{display: "flex", justifyContent: "space-between"}}>
					<p className="title is-6">
                        total ausgaben
					</p>
					<div style={{fontWeight: "bold"}}>
					{
						new Intl.NumberFormat('de-CH', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
							minimumIntegerDigits: 3
							}).format(props.gewinnUndVerlust.total_ausgaben)
					} SFr.
					</div>
				</div>
			</section>
			<section>
				<h2>Einnahmen</h2>
				<hr />
				{_.map(einnahmenKonten, konto => (
						<Konto 
							id={konto.Id} 
							konto={konto} 
							zeitpunktVon={form.datumVon}
							zeitpunktBis={form.datumBis}/>
				))}
                {/* total ausgaben */}
                <div className="media-content" style={{display: "flex", justifyContent: "space-between"}}>
					<p className="title is-6">
                        total einnahmen
					</p>
					<div style={{fontWeight: "bold"}}>
					{
						new Intl.NumberFormat('de-CH', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
							minimumIntegerDigits: 3
							}).format(props.gewinnUndVerlust.total_einnahmen)
					} SFr.
					</div>
				</div>
			</section>
            <br />
			<section>
				<h2>Gewinn / Verlust</h2>
				<hr />
                <div className="media-content" style={{display: "flex", justifyContent: "space-between"}}>
					<p className="title is-6">
                        {moment(form.datumVon).format("DD.MM.YYYY")} - {moment(form.datumBis).format("DD.MM.YYYY")}
					</p>
					<div style={{fontWeight: "bold"}}>
					{
						new Intl.NumberFormat('de-CH', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
							minimumIntegerDigits: 3
							}).format(props.gewinnUndVerlust.total)
					} SFr.
					</div>
				</div>
			</section>
            <br />
		</div>
	);
}

function mapStateToProps(state) {
    return { 
        accounts: state.kontoSlice.konten,
        gewinnUndVerlust: state.gewinnUndVerlustSlice
    }
}
  
export default connect(mapStateToProps)(Income)