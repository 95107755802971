import React, { Component } from 'react';
import { connect } from 'react-redux';

class HasRole extends Component {

  render() {
    const { children, currentUserRoles, requiredRole } = this.props;
    if(currentUserRoles.indexOf(requiredRole) === -1) return null;
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
  }

}

const getMapStateToProps = (extendWith = {}) => state => {
    return {
        currentUserRoles: state.loginSlice.user.resource_access ? state.loginSlice.user.resource_access["subscription manager"].roles : [],
        ...extendWith
    };
};

export default connect(getMapStateToProps())(HasRole);
export const IsAdmin = connect(getMapStateToProps({requiredRole: 'portal admin'}))(HasRole);
export const IsUser = connect(getMapStateToProps({requiredRole: 'user'}))(HasRole);
