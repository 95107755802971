import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import * as _ from "lodash";
import Input from 'components/input';
import { connect, useDispatch } from 'react-redux';
import {getBudgetPositions} from 'store/budgetPositionSlice';
import DataTable from 'react-data-table-component';
import moment from 'moment';

function AccountBudget(props) {
    const routeParams = useParams();
    const dispatch = useDispatch(); 
    const [konto, setKonto] = useState();
    const [accountBudgetPositions, setAccountBudgetPositions] = useState([]);

    // fire initial value
    useEffect(() => {
        const { konten } = props;
        const kontoId = routeParams.id;
        setKonto(_.find(konten, k => { return k.Id == kontoId }));
        dispatch(getBudgetPositions());
	}, [routeParams.id]);

    // fire initial value
    useEffect(() => {
        if(!props.budgetpositions || !konto) return
        setAccountBudgetPositions(_.filter(props.budgetpositions, b => { return b.Haben_Id == konto.Id || b.Soll_Id == konto.Id }));
	}, [props.budgetpositions]);

    const columns = [
        {
            name: "Name",
            selector: row => row.Name
        },
        {
            name: "Betrag",
            selector: row => row.Betrag
        },
        {
            name: "Kadenz",
            selector: row => row.Kadenz
        },
        {
            name: "Von",
            selector: row => moment(row.Von).format("DD.MM.YYYY")
        },
        {
            name: "Bis",
            selector: row => moment(row.Bis).format("DD.MM.YYYY")
        }
    ]

    return (
        <div>
            { konto && 
                <DataTable
                    data={accountBudgetPositions} 
                    columns={columns} />
            }
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        konten: state.kontoSlice.konten,
        budgetpositions: state.budgetPositionSlice.budgetPositions
    };
  }
  
export default connect(mapStateToProps)(AccountBudget)