import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { 
	getBudgetPositions as serviceGetBudgetPositions,
	deleteBudgetPosition as serviceDeleteBudgetPosition,
	createBudgetPositions as serviceCreateBudgetPosition} from 'services/budget'
import _ from '@lodash';

// get all budget positions
export const getBudgetPositions = createAsyncThunk('financeApp/budgetpositions', async (params, {dispatch, getState}) => {
	const budgetPositions = await serviceGetBudgetPositions();

	dispatch(setBudgetPositions(budgetPositions));
});

// delete a budget position
export const deleteBudgetPosition = createAsyncThunk('financeApp/budgetpositions', async ({id}, {dispatch, getState}) => {
	await serviceDeleteBudgetPosition(id);
	dispatch(removeBudgetPosition(id));
});

// create a budget position
export const createBudgetPosition = createAsyncThunk('financeApp/budgetpositions', async (budgetposition, {dispatch, getState}) => {
	await serviceCreateBudgetPosition(budgetposition);
	dispatch(addBudgetPosition(budgetposition));
});

const budgetPositionSlice = createSlice({
	name: 'financeApp/budgetPositions',
	initialState: { budgetPositions: []},
	reducers: {
		setBudgetPositions: (state, action) => { return {...state, budgetPositions: action.payload} },
		removeBudgetPosition: (state, action) => { return {...state, budgetPositions: _.filter(state.budgetPositions, b => { return b.Id != action.payload})} },
		addBudgetPosition: (state, action) => { return {...state, budgetPositions: [...state.budetPositions, action.payload]}  }
	}
});

export const { setBudgetPositions, removeBudgetPosition, addBudgetPosition } = budgetPositionSlice.actions;
export default budgetPositionSlice.reducer;
