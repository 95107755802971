import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";
import config from '../config';

const initialState = {
	token: '',
    user: {},
    loggedIn: false
};

export const logoutUser = () => async dispatch => {
	console.log("loginSlice:loginUserByToken","logout user")
	console.log("loginSlice:loginUserByToken","=> delete token in local store")
	localStorage.removeItem('token');
	console.log("loginSlice:loginUserByToken","=> delete token in redux store")
	await dispatch(setToken(null));
	console.log("loginSlice:loginUserByToken","=> delete user in redux store")
	await dispatch(setUser(null));
	console.log("loginSlice:loginUserByToken","=> set logged in to false in redux store")
	await dispatch(setLoggedIn(false));
};

export const loginUserByToken = createAsyncThunk("loginSlice/token/loginbytoken", async (token, {dispatch, getState}) => {	
		console.log("loginSlice:loginUserByToken","loggin in by token")
        dispatch(setToken(token));
        var decoded = jwt_decode(token);
        dispatch(setUser(decoded));
        dispatch(setLoggedIn(true));

		localStorage.setItem('token', token);
});

export const checkTokenExpired = createAsyncThunk("loginSlice/token/checkexpired", async (dispatch, getState) => {	
	console.log("loginSlice:checkTokenExpired","checking token expiration")
	const token = localStorage.getItem('token');
	if(!token) console.log("there is no token");
	const decoded = jwt_decode(token);
	let currentDate = new Date();

	if (decoded.exp * 1000 < currentDate.getTime()) {
		console.log("loginSlice:checkTokenExpired","token is expired");
		dispatch(logoutUser);
	}else{
		console.log("loginSlice:checkTokenExpired","token is ok");
	}
});

export const interceptToken = createAsyncThunk("loginSlice/token/intercept", async ({params, redirectCallback, redirectToLastPath}, {dispatch, getState}) => {	
	console.log("loginSlice:interceptToken","trying to intercept token")
	
	let access_token = ""
	var regex = /access_token=([^&^$]*)/;
    var match = regex.exec(params.hash);
    if(match) {
		console.log("loginSlice:interceptToken","token intercepted")
		access_token = match[1]
		dispatch(loginUserByToken(access_token));

		redirectToLastPath();
	}else{
		console.log("loginSlice:interceptToken","no access token in url. redirecting to identity provider.", params.hash)
		redirectCallback();
	}
});

const loginSlice = createSlice({
	name: 'login/manage',
	initialState,
	reducers: {
		setToken: (state, action) => {
			return {...state, token: action.payload};
		},
		setUser: (state, action) => {
			return {...state, user: action.payload};
		},
        setLoggedIn: (state, action) => {
			return {...state, loggedIn: action.payload};
		}
	},
	extraReducers: {}
});

export const { setToken, setUser, setLoggedIn } = loginSlice.actions;

export default loginSlice.reducer;
