import React, {useEffect, useState} from 'react';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _ from "lodash";
import { connect } from 'react-redux';
import { IsAdmin } from '../login/hasrole';
import { getApiVersion } from '../../store/infoSlice';
import { useDispatch } from 'react-redux';
import config from '../../config';
import { useNavigate } from "react-router-dom";

function NavBar(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(getApiVersion());
    });

    async function logout() {
        window.location.href = `${config.oauth.logout_url}/?redirect_uri=${config.oauth.redirect_uri}/logout`;
    }

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                <img src="logo_black.png" width="150" alt="logo" />
                </a>

                <div role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start">

                    {/* Bilanz */}
                    <NavLink className="navbar-item" to="/balance">
                        Bilanz
                    </NavLink>

                    {/* GUV */}
                    <NavLink className="navbar-item" to="/guv">
                        Gewinne und Verlust
                    </NavLink>
                    {/* GUV */}
                    <NavLink className="navbar-item" to="/transactions">
                        Transaktionen
                    </NavLink>
                    
                    <div className="navbar-item has-dropdown is-hoverable">
                        <div className="navbar-link">
                            Einstellungen
                        </div>

                        <div className="navbar-dropdown">
                            <b className="ml-2">Einstellungen</b>
                            <NavLink className="navbar-item" to="/budget">
                                Budget
                            </NavLink>
                            <hr className="navbar-divider" />
                            <b className="ml-2">API</b>
                            <NavLink className="navbar-item" to="/settings/apitokens">
                                API Tokens
                            </NavLink>
                            <a className="navbar-item" href={`${config.apibase}`} target="newwindow">
                                API Docs - Version {props.apiVersion}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons" onClick={logout}>
                            <div className="button is-light">
                                Log off
                            </div>
                        </div>
                    </div>

                </div>
            </div>  
        </nav>
    )
}

function mapStateToProps(state) {
    return { 
        apiVersion: state.infoSlice.apiVersion
    };
  }
  
export default connect(mapStateToProps)(NavBar)