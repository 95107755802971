import React from 'react';

function Panel(props) {
    return (
        <nav className="panel" style={props.style ? props.style : null}>
            <p className="panel-heading">
                {props.title}
            </p>
            <div className="panel-block">
                {props.children}
            </div>
        </nav>
    )
}

export default Panel;