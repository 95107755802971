import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
	getSettings as serviceGetSettings,
	createSetting as serviceCreateSetting,
	editSetting as serviceEditSetting
} from 'services/settings';
import * as _ from 'lodash';

const initialState = {
	settings: []
};

// get settings
export const getAllSettings = () => async dispatch => {
	const allSettings = await serviceGetSettings();

    return dispatch(setSettings(allSettings));
};

export const saveSetting = createAsyncThunk("settingsSlice/setting/save", async (setting, {dispatch, getState}) => {	
	// get existing settings
	const settings = getState().settingsSlice.settings;

	// save this setting (redux)
	const existingSetting = _.find(settings, s => { return s.name === setting.name});
	if(existingSetting){
		dispatch(setSetting(setting));
		await serviceEditSetting(existingSetting._id, setting);
	} else {
		dispatch(addSetting(setting));
		await serviceCreateSetting(setting);
	}
});

const settingsSlice = createSlice({
	name: 'provisioners/manage',
	initialState,
	reducers: {
		setSettings: (state, action) => {
			return {...state, settings: action.payload};
		},
		setSetting: (state, action) => {
			return {...state, settings: _.map(state.settings, s => { return s.name === action.payload.name ? action.payload : s})};
		},
		addSetting: (state, action) => {
			return {...state, settings: [...state.settings, action.payload]};
		}
	},
	extraReducers: {}
});

export const { setSettings, setSetting, addSetting } = settingsSlice.actions;

export default settingsSlice.reducer;
