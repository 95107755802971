import _ from '@lodash';
import React from 'react';
import { selectKonten, getKontos } from 'store/kontoSlice';
import Konto from 'modules/konto/konto';
import moment from 'moment';
import { connect } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import Input from 'components/input';
import Panel from 'modules/common/panel/panel'
import useForm from 'components/useForm';
import { getTotalAssets } from 'store/totalAssetsSlice';
import { getGewinnUndVerlust } from 'store/gewinnUndVerlustSlice';

function Balance(props) {
	const dispatch = useDispatch();

	const [aktive, setAktiveKonten] = useState();
	const [passive, setPassiveKonten] = useState();
	
	const { form, handleChange, setForm, setInForm, resetForm } = useForm({
        datum: moment(Date.now()).format("yyyy-MM-DD")
    });

	useEffect(() => {
		dispatch(getKontos());
		dispatch(getTotalAssets(form.datum));
		dispatch(getGewinnUndVerlust({dateFrom: Date.parse("1970-01-01"), dateTo: form.datum}))
	}, []);

	useEffect(() => {
		const { konten } = props;
		setAktiveKonten(_.filter(konten, konto => { return konto.kontotyp == "Aktive"}))
		setPassiveKonten(_.filter(konten, konto => { return konto.kontotyp == "Passive"}))
	}, [props.konten]);

	function zeitPunktHeute() {
		setForm({datum: moment(Date.now()).format("yyyy-MM-DD")});
	}

	function changed(event) {
		event.persist();
		dispatch(getTotalAssets(form.datum));
		handleChange(event)
	}

	return (
		<div>
			<h2>Balance</h2>

			<Panel title="Zeitpunkt">
				{/* DATUM */}
				<Input
					label="Datum"
					type="date"
					id="datum"
					name="datum"
					value={form.datum}
					onChange={changed}
					placeholder=" Choose a date"
					InputLabelProps={{
						shrink: true
					}}
				/>

				<button className="button is-primary m-2 mt-4" onClick={()=>zeitPunktHeute()}>Heute</button>
			</Panel>
			
			
			<section>
				<h3>Aktive</h3>
				<hr />
				{_.map(aktive, konto => (
						<Konto 
						id={konto.Id} 
						konto={konto} 
						zeitpunktVon="1970-01-01"
						zeitpunktBis={form.datum} />
				))}
			</section>
			<section>
				<h3>Passive</h3>
				<hr />
				{_.map(passive, konto => (
						<Konto 
						id={konto.Id} 
						konto={konto} 
						zeitpunktVon="1970-01-01"
						zeitpunktBis={form.datum} />
				))}
				<div className="media-content" style={{display: "flex", justifyContent: "space-between"}}>
					<p className="title is-6">
					Akkumulierter Gewinn / Verlust:
					</p>
					<div>
					{
						new Intl.NumberFormat('de-CH', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
							minimumIntegerDigits: 3
							}).format(props.gewinnUndVerlust.total)
					} SFr.
					</div>
				</div>
				
			</section>
			<br />
			<section>
				<h3>Bilanzsumme</h3>
				<hr />
				<div style={{textAlign: "right", fontWeight: "bold"}}>
				{
					new Intl.NumberFormat('de-CH', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
						minimumIntegerDigits: 3
						}).format(props.totalassets.amount)
				} SFr.
				</div>
			</section>
		</div>
	);
}

function mapStateToProps(state) {
    return { 
        konten: state.kontoSlice.konten,
		totalassets: state.totalAssetsSlice,
		gewinnUndVerlust: state.gewinnUndVerlustSlice
    };
  }
  
export default connect(mapStateToProps)(Balance)