import React, { useState} from 'react';
import moment from 'moment';
import { selectKonten } from 'store/kontoSlice';
import { useSelector, useDispatch, connect } from 'react-redux';
import { createBuchung } from 'store/buchungenSlice';
import Input from 'components/input';
import Select from 'components/select';
import * as _ from 'lodash'
import useForm from 'components/useForm';
import Title from 'modules/common/title/title';
import Pickaccount from 'modules/common/pickaccount/pickaccount';
import { ToastContainer, toast } from 'react-toastify';


// General Focus Hook
const useFocus = (initialFocus = true, id = "") => {
    const [focus, setFocus] = useState(initialFocus)
    const setFocusWithTrueDefault = (param) => setFocus(typeof(param) == "boolean" ? param : true)
    return ([
        setFocusWithTrueDefault, {
            autoFocus: focus,
            key: `${id}${focus}`,
            onFocus: () => setFocus(true),
            onBlur: () => setFocus(false),
        },
    ])
}

function CreateTransaction(props) {
    const dispatch = useDispatch();
    const [setFocus, focusProps] = useFocus(true)

    const { form, handleChange, setForm, setInForm, resetForm } = useForm({
        datum: moment(Date.now()).format("yyyy-MM-DD"),
        bemerkungen: '',
        betrag: '0',
        kontoSoll: '16',
        kontoHaben: '54'
    });

    function doSubmit(event) {
        event.preventDefault();

        dispatch(createBuchung(form));
        toast.info(`Buchung erstellt`);
        resetForm();
        setFocus(true);
    }

    return (
        <div>
            <Title title="Neue Buchung" />
            <ToastContainer />

            <div>
                {/* DATUM */}
                <Input
                    label="Datum"
                    type="date"
                    id="datum"
                    name="datum"
                    value={form.datum}
                    onChange={handleChange}
                    placeholder=" Choose a date"
                    InputLabelProps={{
                        shrink: true
                    }}
                    {...focusProps}
                />
            </div>
            <div>
                {/* BEMERKUNGEN */}
                <Input
                        label="Bemerkungen"
                        type="text"
                        name="bemerkungen"
                        value={form.bemerkungen}
                        onChange={handleChange}
                    />
            </div>
            <div>
                {/* BETRAG */}
                <Input
                        label="Betrag"
                        type="text"
                        name="betrag"
                        value={form.betrag}
                        onChange={handleChange}
                    />
            </div>

            {/* KONTO HABEN */}
            <div>
                <Pickaccount 
                    id="kontoHaben"
                    name="kontoHaben"
                    label="Konto - Haben"
                    onChange={handleChange}
                />
            </div>

            {/* KONTO SOLL */}
            <div>
                <Pickaccount 
                    id="kontoSoll"
                    name="kontoSoll"
                    label="Konto - Soll"
                    onChange={handleChange}
                />
            </div>

            <div>
                <button
					className="mt-16 button is-primary"
					aria-label="save"
					type="submit"
					size="small"
                    onClick={doSubmit}
				>
					Speichern
				</button>
            </div>
            {/* {JSON.stringify(form)} */}
        </div>
    )
}


function mapStateToProps(state) {
    return { accounts: state.kontoSlice.konten }
}
  
export default connect(mapStateToProps)(CreateTransaction)