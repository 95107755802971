import Panel from 'modules/common/panel/panel';
import Title from 'modules/common/title/title';
import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import {NavLink} from 'react-router-dom';
import { getBuchungen } from 'store/buchungenSlice';
import Transactions from './transactions';
import useForm from 'components/useForm';
import * as _ from 'lodash';

function AllTransactions(props) {
    const dispatch = useDispatch();
    const [filteredTransactions, setFilteredTransactions] = useState();

    const { form, handleChange, setForm, setInForm, resetForm } = useForm({
        searchText: ""
    });

    useEffect(() => {
        dispatch(getBuchungen());
    }, []);

    useEffect(() => {
        if(form.searchText == ""){
            setFilteredTransactions(props.transactions);
        }else{
            setFilteredTransactions(_.filter(props.transactions, t => { 
                return t.Bemerkungen && t.Bemerkungen.toLowerCase().indexOf(form.searchText.toLowerCase()) > -1
            }));
        }
    }, [form.searchText]);

    return (
        <React.Fragment>
            <Title title="Alle Transaktionen" />

            <Panel title="Menu">
                <NavLink to="/createtransaction">
                    <button className='button is-primary mb-4'>Neue Buchung</button>
                </NavLink>

                <div className="field mb-4 ml-4">
                    <input 
                        className="input" 
                        type="search" 
                        id="searchText"
                        name="searchText"
                        value={form.searchText}
                        onChange={handleChange}
                        autoFocus
                        placeholder="Suche" />
                </div>
            </Panel>

            <Transactions transactions={filteredTransactions} />
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { transactions: state.buchungenSlice.buchungen }
}
  
export default connect(mapStateToProps)(AllTransactions)
