// console.log(process.cwd())
// require('app-module-path').addPath(process.cwd());

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/js/bootstrap.min.js'
import 'jquery/dist/jquery.min.js'
import "bootstrap/dist/css/bootstrap.css"
import "font-awesome/css/font-awesome.css"
import "react-datepicker/dist/react-datepicker.css";
import 'bulma/css/bulma.min.css';
import 'bulma-switch/dist/css/bulma-switch.min.css'
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
