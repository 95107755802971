import React, {useState} from 'react';
import { useCallback, useEffect } from 'react';
import {NavLink} from 'react-router-dom';
import * as _ from 'lodash';
import { connect } from 'react-redux'
import { getBuchungen } from 'store/buchungenSlice';
import {useDispatch} from 'react-redux';
import { getBudget  } from 'store/kontoSlice';
import moment from 'moment';
import budget from 'modules/budget/budget';

function Konto(props) {
    const dispatch = useDispatch();
    const [sollTransactions, setSollTransactions] = useState();
    const [habenTransactions, setHabenTransactions] = useState();
    const [sumHaben, setSumHaben] = useState(0);
    const [sumSoll, setSumSoll] = useState(0);
    const [zeitpunktVon, setZeitpunktVon] = useState(Date.parse("1970-01-01"));
    const [zeitpunktBis, setZeitpunktBis] = useState(Date.now());

    const [viewBudget, setViewBudget] = useState(false);
    const [budget, setBudget] = useState({});

    const { konto } = props;

    const balance = Number(sumSoll - sumHaben).toFixed(2);

    useEffect(() => {
		dispatch(getBuchungen());
        const von = Date.parse(props.zeitpunktVon) || Date.parse("1970-01-01");
        const bis = Date.parse(props.zeitpunktBis) || Date.now();
        setZeitpunktVon(von);
        setZeitpunktBis(bis);
        dispatch(getBudget({kontoId: konto.Id, von: moment(von).format("yyyy-MM-DD"), bis: moment(bis).format("yyyy-MM-DD")}))
	}, [props.zeitpunktVon, props.zeitpunktBis]);

	useEffect(() => {
        const { transactions } = props;
		setSollTransactions(_.filter(transactions, t => { 
            return t.Konto_Soll_Id == konto.Id && 
            Date.parse(t.Datum) <= zeitpunktBis &&
            Date.parse(t.Datum) >= zeitpunktVon }));
        setHabenTransactions(_.filter(transactions, t => { 
            return t.Konto_Haben_Id == konto.Id && 
            Date.parse(t.Datum) <= zeitpunktBis &&
            Date.parse(t.Datum) >= zeitpunktVon }));
	}, [props.transactions]);

    useEffect(() => {
		setSumHaben(_.sumBy(habenTransactions, t => { return t.Betrag }));
        setSumSoll(_.sumBy(sollTransactions, t => { return t.Betrag }));
	}, [sollTransactions, habenTransactions]);

    useEffect(() => {
        setBudget(_.find(props.budgets, b => { return b.kontoId == konto.Id}))
	}, [props.budgets]);

    function getCurrentBudgetLevel() {
        const result_raw = balance - budget.differenz;
        const result = new Intl.NumberFormat('de-CH', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            minimumIntegerDigits: 1
            }).format(result_raw)

        let color = '#afe9e0';
        if(konto.kontotyp == "Ausgaben" && result_raw > 0) color = '#e9afaf'
        if(konto.kontotyp == "Einnahmen" && result_raw < 0) color = '#e9afaf'

        return (<font style={{cursor: "pointer", color: color}}> {
                `${result} SFr`
            }</font>)
    }

    return (
        <div className="media-content" style={{display: "flex", justifyContent: "space-between"}}>
            <p className="title is-6">
                <NavLink to={`/konto/${konto.Id}`}>
                    {new Intl.NumberFormat('de-CH', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    minimumIntegerDigits: 3
                    }).format(konto.Id)} &nbsp;
                    {konto.Name}
                </NavLink>
            </p>
            <div>
                {/* current value */}
                {!viewBudget &&
                    <div 
                        title={moment(zeitpunktVon).format("yyyy-MM-DD") + " - " + moment(zeitpunktBis).format("yyyy-MM-DD") }
                        style={{cursor: "pointer"}}
                        onClick={()=>setViewBudget(!viewBudget)}>
                            {/* budget */}
                            {budget && getCurrentBudgetLevel() }
                            &nbsp;&nbsp;
                            {
                                new Intl.NumberFormat('de-CH', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                }).format(konto.kontotyp == "Passive" ? balance * -1 : balance)} SFr.
                    </div>
                }
            </div>
        </div>

    )
}

function mapStateToProps(state) {
    return { 
        transactions: state.buchungenSlice.buchungen,
        budgets: state.kontoSlice.budget
    };
  }
  
export default connect(mapStateToProps)(Konto)