import { createSlice } from '@reduxjs/toolkit';
import { 
	getLogs as serviceGetLogs
} from '../services/logs';

const initialState = {
	logs: []
};

export const getAllLogs = () => async dispatch => {
	const allLogs = await serviceGetLogs();

    return dispatch(setLogs(allLogs));
};

const logsSlice = createSlice({
	name: 'logs/manage',
	initialState,
	reducers: {
		setLogs: (state, action) => {
			return {...state, logs: action.payload};
		}
	},
	extraReducers: {}
});

export const { setLogs } = logsSlice.actions;

export default logsSlice.reducer;
