import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment'
import { connect, useDispatch } from 'react-redux';
import {getKontos} from 'store/kontoSlice';
import {NavLink} from 'react-router-dom';

function Transaction(props) {
    const dispatch = useDispatch();
	const {transaction} = props;
	const [habenKonto, setHabenKonto] = useState();
	const [sollKonto, setSollKonto] = useState();

    useEffect(() => {
		setHabenKonto(_.find(props.accounts, a => { return a.Id == transaction.Konto_Haben_Id }));
		setSollKonto(_.find(props.accounts, a => { return a.Id == transaction.Konto_Soll_Id }));
	}, [props.accounts, props.transaction]);

    useEffect(() => {
		dispatch(getKontos());
	}, []);
    


	return (
		<div key={transaction.Id}>
            <table style={{tableLayout: "fixed"}}>
                <tr>
                    <td>
                        {moment(transaction.Datum).format("DD.MM.YYYY")}
                    </td>
                    <td>
                        <NavLink to={`/transactions/${transaction.Id}`}>
                            {transaction.Bemerkungen.trim()}
                        </NavLink>
                    </td>
                    <td>
                        {habenKonto && 
                            <NavLink to={`/konto/${habenKonto.Id}`} >
                                {new Intl.NumberFormat('de-CH', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                minimumIntegerDigits: 3
                                }).format(habenKonto.Id) + " " + habenKonto.Name}
                            </NavLink>
                        }
                    </td>
                    <td>
                        {sollKonto && 
                            <NavLink to={`/konto/${sollKonto.Id}`} >
                                {new Intl.NumberFormat('de-CH', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                minimumIntegerDigits: 3
                                }).format(sollKonto.Id) + " " + sollKonto.Name}
                            </NavLink>
                        }
                    </td>
                    <td style={{textAlign: "right"}}>
                        {new Intl.NumberFormat('de-CH', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        }).format(transaction.Betrag)} SFr.
                    </td>
                </tr>
            </table>
        </div >
	);
}

function mapStateToProps(state) {
    return { 
        accounts: state.kontoSlice.konten
    };
  }
  
export default connect(mapStateToProps)(Transaction)