import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import * as _ from "lodash";
import Input from 'components/input';
import { connect } from 'react-redux';
import { updateAccount } from 'services/konten'

function AccountMetadata(props) {
    const routeParams = useParams();
    const [konto, setKonto] = useState();

    // fire initial value
    useEffect(() => {
        const { konten } = props;
        const kontoId = routeParams.id;
        setKonto(_.find(konten, k => { return k.Id == kontoId }));
	}, [routeParams.id]);

    async function closeAccount() {
        const post = {...konto, closed: true}
        await updateAccount(post)
    }

    return (
        <div>
            { konto && 
                    <div>
                        {/* ID */}
                        <Input
                            label="ID"
                            type="text"
                            name="id"
                            value={konto.Id}
                            variant="outlined"
                            readOnly
                        />

                        {/* TYPE */}
                        <Input
                            label="Typ"
                            type="text"
                            name="kontotyp"
                            value={konto.kontotyp}
                            variant="outlined"
                            readOnly
                        />

                        {/* NAME */}
                        <Input
                            label="Name"
                            type="text"
                            name="name"
                            value={konto.Name}
                            variant="outlined"
                            readOnly
                        />
                        <button 
                            className='button is-danger'
                            onClick={closeAccount}>Konto schliessen</button>
                    </div>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        konten: state.kontoSlice.konten
    };
  }
  
export default connect(mapStateToProps)(AccountMetadata)