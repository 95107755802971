import * as common from './common';
import config from '../config';

// EXPORTED FUNCTIONS
export function getSettings() {
    return common.get(`${config.apibase}/api/settings`);
}

export function editSetting(settingId, setting) {
    return common.put(`${config.apibase}/api/settings/${settingId}`, setting);
}

export function createSetting(setting) {
    return common.post(`${config.apibase}/api/settings`, setting);   
}