import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { 
	getKonten as serviceGetKonten,
	updateAccount as serviceUpdateAccount } from 'services/konten';
import { getKontoVerlauf as serviceGetKontoVerlauf} from 'services/konten'
import { 
	getBudgetVerlauf as serviceGetBudgetVerlauf,
	getBudget as serviceGetBudget } from 'services/budget'
import * as Highcharts from 'highcharts';
import _ from '@lodash';

export const getKontoVerlauf = createAsyncThunk('financeApp/konten/getKontoVerlauf', async ({kontoId, von, bis}, {dispatch, getState}) => {
	if(von == null || bis == null) {
		console.log("von or bis is null"); return
	}
	const saldoVerlauf = await serviceGetKontoVerlauf(kontoId, von, bis);
	const kontobudget = _.map(saldoVerlauf.saldo_verlauf, function (o) {
		return [new Date(o.datum).getTime(), o.betrag];
	});
    
	dispatch(setKontoVerlauf(kontobudget));

	return kontobudget;
});

export const getBudget = createAsyncThunk('financeApp/konten/getBudget', async ({kontoId, von, bis}, {dispatch, getState}) => {
	const budget = await serviceGetBudget(kontoId, von, bis);
	dispatch(setBudget(budget))
});

export const getBudgetVerlauf = createAsyncThunk('financeApp/konten/getBudgetVerlauf', async ({kontoId, von, bis}, {dispatch, getState}) => {
	const budgetVerlauf = await serviceGetBudgetVerlauf(kontoId, von, bis);
	const kontobudget = _.map(budgetVerlauf.kontostand_budgetiert, function (o) {
		return [new Date(o.datum).getTime(), o.betrag];
	});
    
	dispatch(setBudgetVerlauf(kontobudget));

	return kontobudget;
});

export const getKontos = createAsyncThunk('financeApp/konten', async (params, {dispatch, getState}) => {
	const accounts = await serviceGetKonten();

	// convert "Id" parameter to lowecase
	let mappedAccounts = _.map(accounts, m => { return {...m, id: m.Id}});
	mappedAccounts = _.omit(mappedAccounts,['Id']);

	// filter out closed accounts
	let activeAccounts = _.filter(mappedAccounts, a => { return Boolean(a.closed) == false })

	// sort accounts
	let sortedAccounts = _.sortBy(activeAccounts, a => { return a.Name})

	dispatch(setKonten(sortedAccounts));
})

export const updateAccount = createAsyncThunk('financeApp/konten', async (account, {dispatch, getState}) => {
	await serviceUpdateAccount(account)
})

export const selectKontoChart = (state) => {
	const dataKontoVerlauf = state.kontoSlice.kontoVerlauf ? [...state.kontoSlice.kontoVerlauf] : [];
	const dataBudgetVerlauf = state.kontoSlice.budgetVerlauf ? [...state.kontoSlice.budgetVerlauf] : [];
	
	return {
        chart: {
			type: "line",
			height:  (6 / 16 * 100) + '%',
			zoomType: "x",
		  },
		  title: {
			text: "",
		  },
		  credits: {
			enabled: false,
		  },
		  tooltip: {
			formatter: function () {
			  return (
				Highcharts.dateFormat("%e %b %y", this.x) + " - " + this.y.toFixed(2)
			  );
			},
		  },
		  xAxis: {
			type: "date",
			labels: {
			  formatter: function () {
				return Highcharts.dateFormat("%e %b %y", this.value);
			  },
			},
		  },
		  colors: ["#2f7ed8", "#d033a5", "#0d233a"],
		  series: [
			{
				name: "saldo verlauf",
				data: dataKontoVerlauf
		  	},
			{
				name: "budget",
				data: dataBudgetVerlauf
		  	}
		]
    };
}


const kontoSlice = createSlice({
	name: 'financeApp/konten',
	initialState: { 
		konten: [],
		budget: [],
		kontoVerlauf: null,
		budgetVerlauf: null
	},
	reducers: {
		setKontoVerlauf: (state, action) => { return {...state, kontoVerlauf: action.payload} },
		setBudgetVerlauf: (state, action) => { return {...state, budgetVerlauf: action.payload} },
		setBudget: (state, action) => { return {...state, budget: [..._.filter(state.budget, b => { return b.kontoId != action.payload.kontoId }), action.payload]} },
		setKonten: (state, action) => { return { konten: action.payload}}
	}
});

export const { setBudgetVerlauf, setKontoVerlauf, setKonten, setBudget } = kontoSlice.actions;
export default kontoSlice.reducer;
