import Title from 'modules/common/title/title';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect, useDispatch } from 'react-redux';
import { 
    getBudgetPositions,
    deleteBudgetPosition
 } from 'store/budgetPositionSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import _ from 'lodash';
import useForm from 'components/useForm';
import Panel from 'modules/common/panel/panel';
import BudgetPositionsFilter from './filter';

function Budget(props) {
    const dispatch = useDispatch();
    const [filteredBudgetPositions, setFilteredBudgetPositions] = useState();

    useEffect(() => {
        dispatch(getBudgetPositions());
    }, []);

    const columns = [
        {
            name: 'name',
            selector: row => <font style={{whiteSpace: "normal"}}> {row.Name}</font>
        },
        {
            name: 'von',
            selector: row => moment(row.Von).format("DD.MM.yyyy"),
            sortable: true
        },
        {
            name: 'bis',
            sortable: true,
            selector: row => moment(row.Bis).format("DD.MM.yyyy")
        },
        {
            name: 'kadenz',
            selector: row => row.Kadenz
        },
        {
            name: 'haben',
            selector: row => { const acc = _.find(props.accounts, a => { return a.Id == row.Haben_Id}); return acc ? acc.Name : "" }
        },
        {
            name: 'soll',
            selector: row => { const acc = _.find(props.accounts, a => { return a.Id == row.Soll_Id}); return acc ? acc.Name : "" }
        },
        {
            name: 'betrag',
            selector: row => <React.Fragment>{new Intl.NumberFormat('de-CH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                }).format(row.Betrag)} SFr.</React.Fragment>
        },
        {
            name: '',
            selector: row => <FontAwesomeIcon icon="fa-solid fa-trash" className='m-2' role='button' onClick={()=>dispatch(deleteBudgetPosition({id: row.Id}))} />
        }
    ];

    function filterChanges(datumvon, datumbis) {
        console.log(datumvon)
    }

    return (
        <React.Fragment>
            <Title title="Budgetpositionen" />

            

            <BudgetPositionsFilter 
                budgetPositions={props.budgetPositions}
                onChange={setFilteredBudgetPositions}/>

            <DataTable
                columns={columns}
                data={filteredBudgetPositions}
                pagination
                persistTableHead
            />
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return { 
        budgetPositions: state.budgetPositionSlice.budgetPositions,
        accounts: state.kontoSlice.konten
    };
  }
  
export default connect(mapStateToProps)(Budget)