import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

function UserInfo(props) {
    return (
        <React.Fragment>
            <div className="card">
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                            <img src="https://bulma.io/images/placeholders/96x96.png" alt="placeholder" />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4">{props.user.name}</p>
                            <p className="subtitle is-6">{props.user.email}</p>
                        </div>
                    </div>

                    <div className="content">
                        <p>
                            <b>ID</b><br />
                            {props.user.sub}
                        </p>
                        <p>
                            <b>E-Mail</b><br />
                            {props.user.email}
                        </p>
                        <p>
                            <b>Client</b><br />
                            {props.user.azp}
                        </p>
                        <p>
                            <b>Roles</b>
                            <ul>
                            {
                                _.map(props.user.resource_access["provisioner"].roles, r => (
                                    <li>{r}</li>
                                ))
                            }
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { user: state.loginSlice.user };
  }
  
export default connect(mapStateToProps)(UserInfo)