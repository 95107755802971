import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { getTotalAssets as serviceGetTotalAssets } from 'services/totalassets'
import * as Highcharts from 'highcharts';
import _ from '@lodash';

export const getTotalAssets = createAsyncThunk('totalAssetsSlice/getTotalAssets', async (datum, {dispatch, getState}) => {
    console.log(datum)
    const result = await serviceGetTotalAssets(datum);

	dispatch(setTotalAssets(result));
});

const totalAssetsSlice = createSlice({
	name: 'financeApp/totalAssets',
	initialState: { 
		date: null,
        totalAssets: 0.0
    },
	reducers: {
		setTotalAssets: (state, action) => { return action.payload}
	}
});

export const { setTotalAssets } = totalAssetsSlice.actions;
export default totalAssetsSlice.reducer;
