import { createSlice } from '@reduxjs/toolkit';
import { 
	getUsers as serviceGetUsers,
} from '../services/admin-users';

const initialState = {
	users: []
};

export const getAllUsers = () => async dispatch => {
	const allUsers = await serviceGetUsers();

    return dispatch(setUsers(allUsers));
};


const usersSlice = createSlice({
	name: 'users/manage',
	initialState,
	reducers: {
		setUsers: (state, action) => {
			return {...state, users: action.payload};
		}
	},
	extraReducers: {}
});

export const { setUsers } = usersSlice.actions;

export default usersSlice.reducer;
