import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { getKonten as serviceGetKonten } from 'services/konten';
import _ from '@lodash';

const transactionsPerPage = 10;

export const initializeTransactionBrowser = createAsyncThunk(
	'transactionBrowserSlice/initialize',
	async (transactions,{ getState, dispatch }) => {
        // store all transactions
		dispatch(setTransactions(transactions));

        // set current page to 0
        const numPages = Math.ceil(transactions.length / transactionsPerPage);
        dispatch(setPage(0));
        dispatch(setNumPages(numPages));

        // set the current Page Transactions
        const currentPageTransactions = [...transactions.slice(0, transactionsPerPage)]
        dispatch(setCurrentPageTransactions(currentPageTransactions));

        return currentPageTransactions;
	}
);

export const setCurrentPage = createAsyncThunk(
	'transactionBrowserSlice/setCurrentPage',
	async(pageNumber, {dispatch, getState}) => {
		const numPages = getState().transactionBrowserSlice.numPages;
		const transactions = getState().transactionBrowserSlice.transactions;
		const currentPage = pageNumber > numPages ? numPages : pageNumber;

		// set the current Page Transactions
        const currentPageTransactions = [...transactions.slice(currentPage * transactionsPerPage, currentPage * transactionsPerPage + transactionsPerPage)]
        dispatch(setCurrentPageTransactions(currentPageTransactions));

		dispatch(setPage(currentPage));
		return currentPage;
	}
);

export const getCurrentPage = createAsyncThunk(
	'transactionBrowserSlice/getCurrentPage',
	async ({ dispatch, getState }) => {
		const { currentPageTransactions } = getState().transactionBrowserSlice;
		return currentPageTransactions;
	}
);

const transactionBrowserSlice = createSlice({
	name: 'transactionBrowserSlice',
	initialState: null,
	reducers: {
        setTransactions: (state, action) => { return {...state, transactions: action.payload} },
        setPage: (state, action) => { return {...state, page: action.payload} },
        setNumPages: (state, action) => { return {...state, numPages: action.payload} },
        setCurrentPageTransactions: (state, action) => { return {...state, currentPageTransactions: action.payload} },
	},
	extraReducers: {
	}
});

export const { setTransactions, setPage,setNumPages, setCurrentPageTransactions } = transactionBrowserSlice.actions;
export default transactionBrowserSlice.reducer;
