import React from 'react';

function Title(props) {
    return (
        <React.Fragment>
        <div class="columns">
            <div class="column">
                <h3>{props.title}</h3>
            </div>
            <div class="column is-1">
                { props.tag &&
                    <span class="tag is-info">{props.tag}</span>
                }
            </div>
        </div>
        <hr />
        </React.Fragment>
    )
}

export default Title;