import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as _ from "lodash";
import { 
	getAllApiTokens as serviceGetAllApiTokens,
	createApiToken as serviceCreateApiToken,
	deleteApiToken as serviceDeleteApiToken
} from 'services/apitokens';

const initialState = {
	apiTokens: []
};

// retrieve all api tokens
export const getApiTokens = () => async dispatch => {
	const apiTokens = await serviceGetAllApiTokens();

    return dispatch(setTokens(apiTokens));
};

// create a new api token
export const createApiToken = () => async dispatch => {
	const apiToken = await serviceCreateApiToken();

    return dispatch(addToken(apiToken));
};

// delete an api token
export const deleteApiToken = createAsyncThunk("apiTokens/delete", async (tokenId, {dispatch, getState}) => {	
	await serviceDeleteApiToken(tokenId);

    return dispatch(removeToken(tokenId));
});

const apiTokenSlice = createSlice({
	name: 'apiToken/manage',
	initialState,
	reducers: {
		setTokens: (state, action) => {
			return {...state, apiTokens: action.payload};
		},
        addToken: (state, action) => {
            return {...state, apiTokens: [...state.apiTokens, action.payload]};
        },
		removeToken: (state, action) => {
			return {...state, apiTokens: _.filter(state.apiTokens, a => { return a._id != action.payload })};
		}
	},
	extraReducers: {}
});

export const { setTokens, addToken, removeToken } = apiTokenSlice.actions;

export default apiTokenSlice.reducer;