import Menu from './modules/menu/menu';
import Logout from 'modules/logout/logout';
import Settings from './modules/settings/settings';
import Home from './modules/home/home';
import Logs from './modules/logs/logs';
import LoginController from './modules/login/controller';
import Provider from 'react-redux/es/components/Provider';
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import React from 'react';
import store from './store';

import NavBar from './modules/menu/navbar';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'

// css
import './App.css';

// config
import config from './config';
import Footer from './modules/footer/footer';
import ApiTokens from 'modules/settings/admin/apiTokens';
import Userinfo from 'modules/settings/userinfo';
import Balance from 'modules/balance/balance';
import KontoDetails from 'modules/konto/kontoDetails';
import Income from 'modules/income/income';
import CreateTransaction from 'modules/transactions/createTransaction';
import Budget from 'modules/budget/budget';
import CreateBudgetPosition from 'modules/budget/createBudgetPosition';
import Transactions from 'modules/transactions/transactions';
import AllTransactions from 'modules/transactions/alltransactions';
import TransactionDetails from 'modules/transactions/transactionDetails';

library.add(fas, faTwitter, faFontAwesome)


function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
          <Router>
            <LoginController>
            <NavBar />
            <div className="container">
              <Menu />
              <div className="content">
                <Routes>
                  <Route path="/settings" element={ <Settings /> } />
                  <Route path="/userinfo" element={ <Userinfo /> } />
                  <Route path="/budget" element={ <Budget /> } />
                  <Route path="/createbudgetposition" element={ <CreateBudgetPosition /> } />
                  <Route path="/transactions/:id" element={ <TransactionDetails /> } />
                  <Route path="/transactions" element={ <AllTransactions /> } />

                  <Route path="/balance" element={<Balance /> } />
                  <Route path="/createtransaction" element={<CreateTransaction /> } />
                  <Route path="/guv" element={<Income /> } />
                  <Route path="/konto/:id" element={<KontoDetails /> } />

                  {/* Admin */}
                  {/* <Route path="/settings/users/:id" element={ <UserDetails /> } /> */}
                  <Route path="/settings/apitokens" element={ <ApiTokens /> } />

                  {/* Logout */}
                  <Route path="/logout" element={ <Logout /> } />

                  <Route path="/" element={ <Balance /> } />
                </Routes>
              </div>
            </div>
            </LoginController>
            <Footer />
          </Router>
      </Provider>
    </React.Fragment>
  );
}

export default App;
