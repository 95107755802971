import * as common from './common';
import config from 'config';

export function getKonten() {
    return common.get(`${config.apibase}/api/konten`);
}

export function getKontoVerlauf(kontoId, von, bis) {
    return common.get(`${config.apibase}/api/konten/kontoverlauf/${kontoId}?von=${von}&bis=${bis}`);
}

export function updateAccount(konto) {
    return common.put(`${config.apibase}/api/konten/${konto.Id}`, konto)
}