import * as common from './common';
import config from 'config';

// EXPORTED FUNCTIONS
export function getAllTransactions() {
    return common.get(`${config.apibase}/api/buchungen`);
}

export function createTransaction(datum, betrag, bemerkungen, konto_haben_id, konto_soll_id) {
    return common.post(`${config.apibase}/api/buchungen`,{
        datum,
        betrag,
        bemerkungen,
        konto_haben_id,
        konto_soll_id
    })
}

export function updateTransaction(id, datum, betrag, bemerkungen, konto_haben_id, konto_soll_id) {
    return common.put(`${config.apibase}/api/buchungen/${id}`,{
        datum,
        betrag,
        bemerkungen,
        konto_haben_id,
        konto_soll_id
    })
}