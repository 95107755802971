import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from '@lodash';

const balanceSlice = createSlice({
	name: 'financeApp/balance',
	initialState: null,
	reducers: {
	},
	extraReducers: {
	}
});

export default balanceSlice.reducer;
