import React from 'react';

function Card(props) {
    return (
        <div class="card">
            <div class="card-content">
                <div class="content">
                    <p class="title is-4">{props.title}</p>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Card;