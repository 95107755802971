import * as common from './common';
import config from '../config';

// get all api tokens
export function getAllApiTokens() {
    return common.get(`${config.apibase}/api/apitokens`);
}

// create a new api token
export function createApiToken() {
    return common.post(`${config.apibase}/api/apitokens`, null);
}

// delete an api token
export function deleteApiToken(tokenId) {
    return common.del(`${config.apibase}/api/apitokens/${tokenId}`);
}