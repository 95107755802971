import _ from '@lodash';
import Transaction from './transaction';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTransactions } from 'store/buchungenSlice';
import { initializeTransactionBrowser, setCurrentPage } from 'store/transactionBrowserSlice';
import {getBuchungen, updateTransaction} from 'store/buchungenSlice';

function Transactions(props) {
	const dispatch = useDispatch();
	const { transactions } = props;

	const currentPageTransactions = useSelector((state) => state.transactionBrowserSlice ? state.transactionBrowserSlice.currentPageTransactions : [])
	const currentPage  = useSelector((state) => state.transactionBrowserSlice ? state.transactionBrowserSlice.page : 0)
	const numPages  = useSelector((state) => state.transactionBrowserSlice ? state.transactionBrowserSlice.numPages : 0)

	useEffect(() => {
		dispatch(getBuchungen())
	}, []);

	useEffect(() => {
		// initialize transactionbrowser state
		dispatch(initializeTransactionBrowser(transactions));
	}, [transactions]);

	function setPage(ev, page) {
		ev.preventDefault();
		dispatch(setCurrentPage(page));
	}

	return (
		<React.Fragment>
			{ /* TRANSACTIONS */}
			{_.map(currentPageTransactions, t => (
				<Transaction transaction={ t } />
			))}
			
			<br />
			<button
				className='button is-primary'
				onClick={ev => setPage(ev,currentPage - 1)}
				disabled={currentPage == 0}>
				&lt; Previous
			</button>
			
			<button
				className='button is-primary ml-2'
				onClick={ev => setPage(ev,currentPage + 1)}
				disabled={currentPage >= numPages - 1}>
				Next &gt;
			</button>
			
		</React.Fragment>
	);
}

export default Transactions;
