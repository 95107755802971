import * as common from './common';
import config from 'config';

export function getBudgetPositions() {
    return common.get(`${config.apibase}/api/budgetpositions`);
}

export function createBudgetPositions(budgetposition) {
    return common.post(`${config.apibase}/api/budgetpositions`, budgetposition);
}


export function deleteBudgetPosition(id) {
    return common.del(`${config.apibase}/api/budgetpositions/${id}`);
}

export function getBudgetVerlauf(kontoId, von, bis) {
    return common.get(`${config.apibase}/api/budget/${kontoId}?von=${von}&bis=${bis}`);
}

export function getBudget(kontoId, zeitpunktVon, zeitpunktBis) {
    return common.get(`${config.apibase}/api/budget/${kontoId}?bis=${zeitpunktBis}&von=${zeitpunktVon}`);
}
