import React, {useState, useEffect} from 'react';
import useForm from 'components/useForm';
import moment from 'moment';
import Panel from 'modules/common/panel/panel';
import Input from 'components/input';
import * as _ from 'lodash';
import {NavLink} from 'react-router-dom';

import { 
	date_startOfYear,
	date_endOfYear,
	zeitraumDiesesJahr,
	zeitraumAngefangenesJahr,
	zeitraumLetztesJahr,
	zeitraumDieserMonat,
	zeitraumLetzterMonat,
	date_today
} from 'services/zeitpunkte'

// filter for budget positions
// => date from
// => date to
// => name
function BudgetPositionsFilter(props) {

    const { form, handleChange, setForm, setInForm, resetForm } = useForm({
        datumVon: moment(date_startOfYear).format("yyyy-MM-DD"),
        datumBis: moment(date_today).format("yyyy-MM-DD"),
        searchText: ""
    });

    useEffect(() => {
        filterBudgetPositions()
	}, [form.datumVon, form.datumVon, form.searchText]);

    useEffect(() => {
        console.log(zeitraumDiesesJahr())
        dateSetter(zeitraumDiesesJahr)
	}, []);

    // filter function
    function filterBudgetPositions() {
        if(!props.onChange) return
        let budgetPositions = props.budgetPositions

        // filter by datefilter
        budgetPositions = _.filter(budgetPositions, b => { 
            return (moment(b.Von) < moment(form.datumVon) && moment(b.Bis) > moment(form.datumVon)) || (moment(b.Von) < moment(form.datumBis) && moment(b.Bis) > moment(form.datumBis)) || moment(b.Von) >= moment(form.datumVon) && moment(b.Bis) <= moment(form.datumBis)
        })

        // filter by searchtext
        if(form.searchText == "") {
            props.onChange(budgetPositions);
        }else{
            try{
                props.onChange(_.filter(budgetPositions, b => { return b.Name.toLowerCase().indexOf(form.searchText.toLowerCase()) > -1 }))
            }catch(ex){}
        }
    }

    function dateSetter(zeitraum) {
        setInForm("datumVon", moment(zeitraum().datumVon).format("yyyy-MM-DD"))
        setInForm("datumBis", moment(zeitraum().datumBis).format("yyyy-MM-DD"))
    }

    return (
        <React.Fragment>
            <div class="columns">
                <div class="column">
                    <Panel title="Menu" style={{height: "150px"}}>
                        <NavLink to="/createbudgetposition">
                            <button className='button is-primary mb-4'>Neue Budgetposition</button>
                        </NavLink>
                    </Panel>
                </div>
                <div class="column">
                    <Panel title="Suche" style={{height: "150px"}}>
                        <input  
                            className="input" 
                            type="search" 
                            id="searchText"
                            name="searchText"
                            value={form.searchText}
                            onChange={handleChange}
                            autoFocus
                            placeholder="Suche" />
                    {/* {JSON.stringify(form)} */}
                    </Panel>
                </div>
            </div>
        <Panel title="Filter">
            <div>
                {/* DATUM VON */}
                <Input
                    label="Datum Von"
                    type="date"
                    id="datumVon"
                    name="datumVon"
                    value={form.datumVon}
                    onChange={handleChange}
                    placeholder=" Choose a date"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </div>
            <div className='ml-2'>
                {/* DATUM BIS */}
                <Input
                    label="Datum Bis"
                    type="date"
                    id="datumBis"
                    name="datumBis"
                    value={form.datumBis}
                    onChange={handleChange}
                    placeholder=" Choose a date"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </div>
            <button className='button is-primary ml-4 mt-4' onClick={()=>dateSetter(zeitraumDiesesJahr)}>Dieses Jahr</button>
            <button className='button is-primary ml-4 mt-4' onClick={()=>dateSetter(zeitraumLetztesJahr)}>Letztes Jahr</button>
            <button className='button is-primary ml-4 mt-4' onClick={()=>dateSetter(zeitraumDieserMonat)}>Dieser Monat</button>
            <button className='button is-primary ml-4 mt-4' onClick={()=>dateSetter(zeitraumLetzterMonat)}>Letzter Monat</button>
        </Panel>
        </React.Fragment>
    )
}

export default BudgetPositionsFilter