import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { getGewinnUndVerlust as serviceGetGewinnUndVerlust } from 'services/guv'
import * as Highcharts from 'highcharts';
import _ from '@lodash';

export const getGewinnUndVerlust = createAsyncThunk('gewinnUndVerlustSlice/getGewinnUndVerlust', async ({dateFrom, dateTo}, {dispatch, getState}) => {
    const result = await serviceGetGewinnUndVerlust(dateFrom, dateTo);

	dispatch(setGuv(result));
});

const gewinnUndVerlustSlice = createSlice({
	name: 'financeApp/gewinnUndVerlust',
	initialState: { 
		date_from: null,
		date_to: null,
        total_einnahmen: 0.0,
        total_ausgaben: 0.0,
        total: 0.0
    },
	reducers: {
		setGuv: (state, action) => { return action.payload}
	}
});

export const { setGuv } = gewinnUndVerlustSlice.actions;
export default gewinnUndVerlustSlice.reducer;
