import Title from 'modules/common/title/title';
import React, { useEffect, useRef } from 'react';
import * as _ from 'lodash'
import useForm from 'components/useForm';
import { useSelector, useDispatch, connect } from 'react-redux';
import moment from 'moment';
import Input from 'components/input';
import Pickaccount from 'modules/common/pickaccount/pickaccount';
import {getKontos} from 'store/kontoSlice';
import Select from 'components/select';
import { ToastContainer, toast } from 'react-toastify';
import { createBudgetPosition } from 'store/budgetPositionSlice'

function CreateBudgetPosition(props) {
    const dispatch = useDispatch();

    const today = new Date(Date.now());
    const startOfYear = new Date(today.setMonth(0)).setDate(0);
    const endOfYear = new Date(today.setMonth(12)).setDate(31);

    const kadenzen = [
        { id: "T", name: "täglich"},
        { id: "M", name: "monatlich"},
        { id: "J", name: "jährlich"}
    ]

    const { form, handleChange, setForm, setInForm, resetForm } = useForm({
        name: "-",
        von: moment(startOfYear).format("yyyy-MM-DD"),
        bis: moment(endOfYear).format("yyyy-MM-DD"),
        konto_haben_id: "",
        konto_soll_id: "",
        kadenz: "M",
        betrag: 0
    });

    useEffect(() => {
        dispatch(getKontos());
    }, []);
    useEffect(() => {
        props.accounts.length && setInForm("konto_haben_id", props.accounts[0].Id)
        props.accounts.length && setInForm("konto_soll_id", props.accounts[0].Id)
    }, [props.accounts]);

    function doSubmit(event) {
        event.preventDefault();

        dispatch(createBudgetPosition(form));
        toast.info(`Budgetposition erstellt`);
        resetForm();
    }

    return (
        <React.Fragment>
            <Title title="Neue Budgetposition" />
            <ToastContainer />
            <div>
                {/* NAME */}
                <Input
                    label="Name"
                    type="string"
                    id="name"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                {/* DATUM VON */}
                <Input
                    label="Datum von"
                    type="date"
                    id="von"
                    name="von"
                    value={form.von}
                    onChange={handleChange}
                    placeholder=" Choose a date"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                {/* DATUM BIS */}
                <Input
                    label="Datum bis"
                    type="date"
                    id="bis"
                    name="bis"
                    value={form.bis}
                    onChange={handleChange}
                    placeholder=" Choose a date"
                    InputLabelProps={{
                        shrink: true
                    }}
                />

                {/* KONTO HABEN */}
                <Pickaccount 
                    id="konto_haben_id"
                    name="konto_haben_id"
                    label="Konto - Haben"
                    onChange={handleChange}
                />

                {/* KONTO SOLL */}
                <Pickaccount 
                    id="konto_soll_id"
                    name="konto_soll_id"
                    label="Konto - Soll"
                    onChange={handleChange}
                />

                {/* KADENZ */}
                <Select 
                    label="Kadenz"
                    options={kadenzen}
                    name="kadenz"
                    id="kadenz"
                    value={form.kadenz}
                    onChange={handleChange}
                />

                {/* BETRAG */}
                <Input 
                    label="Betrag (SFr)"
                    name="betrag"
                    id="betrag"
                    value={form.betrag}
                    onChange={handleChange}
                />

                <button 
                    className='button is-primary'
                    onClick={doSubmit}>Erstellen</button>
            </div>
        </React.Fragment>
    )
}
function mapStateToProps(state) {
    return { 
        accounts: state.kontoSlice.konten
    };
  }
  
export default connect(mapStateToProps)(CreateBudgetPosition)