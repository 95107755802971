import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import { getAllLogs } from '../../store/logsSlice'
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';


function Logs(props) {
    const dispatch = useDispatch();
    const columns = [
        {
            name: 'Time',
            selector: row => row["_source"].time,
            sortable: true,
        },
        {
            name: 'Message',
            selector: row => row["_source"].message
        }
    ];

    useEffect(() => {
        dispatch(getAllLogs());
    }, [dispatch]);

    return (
        <React.Fragment>
            <h1>Synchronizer Logs</h1>
            <DataTable
                columns={columns}
                data={props.logs}
                pagination
                persistTableHead
            />
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { logs: state.logsSlice.logs }
}
  
export default connect(mapStateToProps)(Logs)