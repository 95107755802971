import React, { useEffect, useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getKontoVerlauf, selectKontoChart, getBudgetVerlauf } from 'store/kontoSlice';
import { useDispatch, useSelector, connect } from 'react-redux';
import moment from 'moment';
import useForm from 'components/useForm';
import Panel from 'modules/common/panel/panel';
import Input from 'components/input';
import {
    date_today,
    date_endOfNextYear,
    date_threeYearsAgo
} from 'services/zeitpunkte';

function KontoChart(props) {
    const dispatch = useDispatch();
    const kontoChart = useSelector(selectKontoChart);

    const today = new Date(Date.now());
    const startOfYear = new Date(today.setMonth(0)).setDate(0);
    const endOfYear = new Date(today.setMonth(12)).setDate(31);
    
    const startOfLastYear = moment(startOfYear).add(-1, 'Y');
    const endOfLastYear = moment(endOfYear).add(-1, 'Y');

    const threeYearsAgo  = moment(startOfYear).add(-3, 'Y');
    
    const { form, handleChange, setForm, setInForm, resetForm } = useForm({
        datumVon: moment(startOfYear).format("yyyy-MM-DD"),
        datumBis: moment(endOfYear).format("yyyy-MM-DD")
    });
    
    useEffect(() => {
        dispatch(getKontoVerlauf({kontoId: props.kontoId, von: form.datumVon, bis: form.datumBis}));
        dispatch(getBudgetVerlauf({kontoId: props.kontoId, von: form.datumVon, bis: form.datumBis}));
        window.dispatchEvent(new Event('resize'));
	}, [form.datumBis, form.datumVon]);

    function zeitpunktDiesesJahr() {
        setForm({
            datumVon: moment(startOfYear).format("yyyy-MM-DD"),
            datumBis: moment(endOfYear).format("yyyy-MM-DD")
        })
    }

    function zeitpunktLetztesJahr() {
        setForm({
            datumVon: moment(startOfLastYear).format("yyyy-MM-DD"),
            datumBis: moment(endOfLastYear).format("yyyy-MM-DD")
        })
    }

    function zeitpunktLast3Years() {
        setForm({
            datumVon: moment(date_threeYearsAgo).format("yyyy-MM-DD"),
            datumBis: moment(endOfYear).format("yyyy-MM-DD")
        })
    }

    function zeitpunktNaechstePeriode() {
        setForm({
            datumVon: moment(date_today).format("yyyy-MM-DD"),
            datumBis: moment(date_endOfNextYear).format("yyyy-MM-DD")
        })
    }

    return (
        <React.Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={kontoChart}
                containerProps={{ 
                    style: { 
                        width: "100%",
                        height:  (9 / 16 * 100) + '%' 
                    } 
                }}
            />
            <Panel title="Chart - Kontoverlauf">
                <div>
                    {/* DATUM VON */}
                    <Input
                        label="Datum Von"
                        type="date"
                        id="datumVon"
                        name="datumVon"
                        value={form.datumVon}
                        onChange={handleChange}
                        placeholder=" Choose a date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
                <div className='ml-2'>
                    {/* DATUM BIS */}
                    <Input
                        label="Datum Bis"
                        type="date"
                        id="datumBis"
                        name="datumBis"
                        value={form.datumBis}
                        onChange={handleChange}
                        placeholder=" Choose a date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
                <div className='mt-4 ml-4'>
                    <button className='button is-primary' onClick={zeitpunktDiesesJahr}>Dieses Jahr</button>
                </div>
                <div className='mt-4 ml-4'>
                    <button className='button is-primary' onClick={zeitpunktLetztesJahr}>Letztes Jahr</button>
                </div>
                <div className='mt-4 ml-4'>
                    <button className='button is-primary' onClick={zeitpunktLast3Years}>Letzte 3 Jahre</button>
                </div>
                <div className='mt-4 ml-4'>
                    <button className='button is-primary' onClick={zeitpunktNaechstePeriode}>Forecast</button>
                </div>
			</Panel>
        </React.Fragment>
    )
}

export default KontoChart;
