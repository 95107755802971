import React from 'react';
import moment from 'moment';
import { selectKonten } from 'store/kontoSlice';
import { useSelector, useDispatch, connect } from 'react-redux';
import { createBuchung } from 'store/buchungenSlice';
import Input from 'components/input';
import Select from 'components/select';
import * as _ from 'lodash'
import useForm from 'components/useForm';
import Title from 'modules/common/title/title';

function PickAccount({id, name, label, value, onChange, accounts, ...rest}) {
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Select
                    id={id}
                    name={name}
                    label={label}
                    value={value}
                    onChange={onChange}
                    placeholder="Select Account"
                    margin="normal"
                >
                    <optgroup label='aktive'>
                    { accounts && _.map(accounts, konto => (
                        konto.kontotyp == "Aktive" &&
                        <option key={konto.Id} value={konto.Id}>
                            {konto.Id} {konto.Name}
                        </option>
                    ))}
                    </optgroup>
                    <optgroup label='passive'>
                    { accounts && _.map(accounts, konto => (
                        konto.kontotyp == "Passive" &&
                        <option key={konto.Id} value={konto.Id}>
                            {konto.Id} {konto.Name}
                        </option>
                    ))}
                    </optgroup>
                    <optgroup label='einnahmen'>
                    { accounts && _.map(accounts, konto => (
                        konto.kontotyp == "Einnahmen" &&
                        <option key={konto.Id} value={konto.Id}>
                            {konto.Id} {konto.Name}
                        </option>
                    ))}
                    </optgroup>
                    <optgroup label='ausgaben'>
                    { accounts && _.map(accounts, konto => (
                        konto.kontotyp == "Ausgaben" &&
                        <option key={konto.Id} value={konto.Id}>
                            {konto.Id} {konto.Name}
                        </option>
                    ))}
                    </optgroup>
            </Select>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { accounts: state.kontoSlice.konten }
}
  
export default connect(mapStateToProps)(PickAccount)
